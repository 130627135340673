import { Router, Link } from "@reach/router";

import CreatePost from './components/createPost'
import Posts from './components/posts'

const NotFound = () => {
  return (
    <div style={{ margin: '10px', textAlign: 'center' }} >
      <h1>404 Not Found</h1>
      <Link to="/">Go Home</Link>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Posts path="/" />
      <CreatePost path="/create" />
      <NotFound default />
    </Router>
  );
}

export default App;
