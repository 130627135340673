import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
} from '@mui/material';
import { navigate } from "@reach/router";

const defaultState = {
    value: '',
    error: false,
};

const CreatePost = () => {
    const [title, setTitle] = useState({ ...defaultState });
    const [username, setUsername] = useState({ ...defaultState });
    const [content, setContent] = useState({ ...defaultState });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!title.value) {
            setTitle({ ...title, error: true });
        }
        if (!username.value) {
            setUsername({ ...username, error: true });
        }
        if (!content.value) {
            setContent({ ...content, error: true });
        }
        if (title.value && username.value && content.value) {
            const post = {
                title: title.value,
                username: username.value,
                content: content.value,
            };

            fetch('https://worker.ilhamsyahids.workers.dev/posts', {
                method: 'POST',
                body: JSON.stringify(post),
            })
                .then(() => navigate('/'))
                .catch(err => console.log(err));
        }
    };

    return (
        <div style={{ padding: "1rem" }}>
            <h1>Create Post</h1>
            <Box
                component="form"
                action="https://worker.ilhamsyahids.workers.dev/posts"
                method="POST"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '35ch' },
                }}
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <div>
                    <TextField
                        required
                        id="title"
                        label="Title"
                        variant="standard"
                        error={title.error}
                        value={title.value}
                        onChange={(e) => {
                            setTitle({
                                value: e.target.value,
                                error: false,
                            });
                        }}
                    />
                    <TextField
                        required
                        id="username"
                        label="Username"
                        variant="standard"
                        error={username.error}
                        value={username.value}
                        onChange={(e) => {
                            setUsername({
                                value: e.target.value,
                                error: false,
                            });
                        }}
                    />
                </div>
                <div>
                    <TextField
                        id="content"
                        label="Content"
                        required
                        multiline
                        maxRows={4}
                        error={content.error}
                        value={content.value}
                        onChange={(e) => {
                            setContent({
                                value: e.target.value,
                                error: false,
                            });
                        }}
                    />
                </div>
                <Button variant="contained" type="submit">Submit</Button>
            </Box>

        </div>
    );
};

export default CreatePost;
