import React, { useEffect, useState } from "react";
import moment from 'moment';

import {
    Chip,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemButton,
    Divider,
    Avatar,
    Typography,
} from '@mui/material';
import { navigate } from "@reach/router";

const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const getPosts = async () => {
        try {
            const response = await fetch("https://worker.ilhamsyahids.workers.dev/posts");
            const data = await response.json();
            const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            setPosts(sortedData);
        } catch (error) {
            setIsError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <div style={{ padding: "1rem" }}>
            <h1>All Posts</h1>
            <Chip label="Create Post" variant="outlined" onClick={() => navigate('/create')} />
            {isError && <p>Something went wrong</p>}
            {loading ? (
                <p>Loading...</p>
            ) : posts.map(({ title, content, username, createdAt }, idx) => (
                <List key={idx}>
                    <ListItemButton>
                        <ListItem disablePadding>
                            <ListItemAvatar style={{ marginRight: '10px' }}>
                                <Avatar
                                    alt={username}
                                    src={`https://i.pravatar.cc/150?u=${username}`}
                                    sx={{ width: 56, height: 56 }}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={title}
                                primaryTypographyProps={{ variant: 'h4' }}
                                secondaryTypographyProps={{ variant: 'div' }}
                                secondary={
                                    <React.Fragment>
                                        <Typography>
                                            {username}
                                        </Typography>
                                        <Typography
                                            color="text.primary"
                                            marginTop={2}
                                        >
                                            {content}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                            <Typography
                                color="text.primary"
                            >
                                {moment(createdAt).fromNow()}
                            </Typography>
                        </ListItem>
                    </ListItemButton>
                    <Divider />
                </List>)
            )}
        </div>
    );
};

export default Posts;
